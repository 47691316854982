const ButtonLoading = ({title, loading, background}) => {
    return (
        <button
            className="btn btn-primary d-block w-100 mt-3"
            style={{
                background: background
            }}>
            {
                loading ?
                    <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
                    </>
                    : title
            }
        </button>
    )
}

export default ButtonLoading