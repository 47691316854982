import React, { useState, useEffect } from "react";

const CountdownTimer = ({ endDateDefault }) => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const endDate = new Date(endDateDefault).getTime();
      const distance = endDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setCountdown({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountdown({
          days,
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endDateDefault]);

  return (
    <div className="timer_countdown-timer__ztnnf col-md-8 mx-auto">
      <ul className="d-flex justify-content-center">
        <li>
          <span className="timer_timer-days__Y9csX">{countdown?.days}</span>
          Ngày
        </li>
        <li>
          <span className="timer_timer-hours__j5_uk">{countdown?.hours}</span>
          Giờ
        </li>
        <li>
          <span className="timer_timer-minutes__yUNpB">
            {countdown?.minutes}
          </span>
          Phút
        </li>
        <li>
          <span className="timer_timer-seconds__F_UaK">
            {countdown?.seconds}
          </span>
          Giây
        </li>
      </ul>
    </div>
  );
};

export default CountdownTimer;
