import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import quizApi from "../api/quizApi";
export const STATUS = {
  CHUA_THI: 1,
  DANG_THI: 2,
  DA_THI: 3,
};
const Quiz = () => {
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState();
  const { quizId } = useParams();
  useEffect(() => {
    const getQuizById = async (quizId) => {
      try {
        const res = await quizApi.getById(quizId);
        if (res.isCompleted) {
          navigate("/");
        }
        setQuiz(res);
      } catch (error) {
        console.log(error);
      }
    };
    getQuizById(quizId);
  }, [quizId]);

  const returnDateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY - HH:mm");
  };
  return (
    <div>
      {/* end header */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n            .tablee {\n              border: 0;\n              border-spacing: 0;\n              font-size: 0.857em;\n              margin: 10px 0;\n              width: 100%;\n            }\n            .tbodyy {\n              border-top: 1px solid #ccc;\n            }\n            tr.oddd {\n              background: #e4e4e4;\n              background: rgba(0, 0, 0, 0.105);\n            }\n            tr th {\n              color: white;\n              font-weight: bold;\n\n              background: rgba(0, 0, 0, 0.51);\n              border-bottom-style: none;\n            }\n            #quiz-view-table td {\n              padding: 0 3px 0 3px;\n              margin: 0;\n              line-height: 110%;\n            }\n            tr td {\n              color: black;\n            }\n            tr th {\n              padding: 4px 9px;\n              border: 1px solid #fff;\n              text-align: left;\n            }\n            .quiz-start-link {\n              font-size: 16px;\n              border: 1px solid #4e4e4e;\n              padding: 8px;\n              text-decoration: none;\n              border-radius: 4px;\n              color: white;\n              font-weight: bold;\n              background: #006abc;\n            }\n            @media screen and (max-width: 767px) {\n              .oddd th {\n                width: 50%;\n              }\n              .content {\n                min-height: unset;\n              }\n            }\n          ",
        }}
      />
      <main>
        <div className="container">
          <div className="row g-3 mb-3" style={{ marginTop: "50px" }}>
            <div className="col-xxl-12 col-xl-12">
              <div
                className="card mb-3"
                style={{ padding: "20px", height: "300px" }}>
                <div className="content clearfix">
                  <table className="tablee" id="quiz-view-table">
                    <tbody className="tbodyy">
                      <tr className="oddd">
                        <th width="25%">Câu hỏi</th>
                        <td>{quiz?.questions?.length}</td>
                      </tr>
                      <tr className="even">
                        <th>Số lần làm bài thi được phép</th>
                        <td>1</td>
                      </tr>
                      <tr className="oddd">
                        <th>Ngày bắt đầu bài thi</th>
                        <td>{returnDateFormat(quiz?.startTime)}</td>
                      </tr>
                      <tr className="even">
                        <th>Ngày kết thúc bài thi</th>
                        <td>{returnDateFormat(quiz?.endTime)}</td>
                      </tr>
                      <tr className="oddd">
                        <th>Thời gian làm bài thi</th>
                        <td>0:20:00</td>
                      </tr>
                      {/* <tr className="even">
                        <th>Sửa câu hỏi bất kỳ trong bài thi</th>
                        <td>Được phép</td>
                      </tr> */}
                    </tbody>
                  </table>
                  <button
                    onClick={() => {
                      navigate(`/exam/${quizId}`);
                    }}
                    className="quiz-start-link">
                    Làm bài thi
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* footer */}
      <div className="row g-0" style={{ backgroundColor: "#006abc" }}>
        <div className="col-lg-12 mb-12">
          <div
            className="card h-lg-100 overflow-hidden"
            style={{ background: "#006abc" }}>
            <div className="container">
              <div className="row" style={{ background: "#006abc" }}>
                <div
                  className="col-sm-12 col-md-7 d-flex"
                  style={{
                    display: "flex",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                  }}>
                  <div className="footer-logo">
                    <img
                      src="https://tuyengiao-tracnghiem.laocai.gov.vn/images/logo-dang.png"
                      alt="HỆ THỐNG THI TRẮC NGHIỆM TRỰC TUYẾN "
                      className="img-responsive"
                    />
                  </div>
                  <div
                    className="footer-content"
                    style={{ padding: "0px 10px" }}>
                    <h3>HỆ THỐNG THI TRẮC NGHIỆM TRỰC TUYẾN</h3>
                    <p>
                      <strong>- Cơ quan phụ trách:</strong> Ban Tuyên giáo Tỉnh
                      ủy
                      <br />
                      <strong>- Địa chỉ:</strong> Trụ sở khối 1, phường Nam
                      Cường, Thành phố , Tỉnh <br />
                      <strong>- Điện thoại:</strong> 0987733a2323
                      <br />
                      <strong>- Email:</strong> dasdasdasdasd@laocai.gov.vn
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 pull-right">
                  <nav className="nav nav-footer">
                    <a className="nav-link" href="/">
                      Trang chủ
                    </a>
                    <a className="nav-link" href="/article/index?code=hd">
                      Hướng dẫn
                    </a>
                    <a className="nav-link" href="/article/index?code=lh">
                      Liên hệ
                    </a>
                    <a className="nav-link" href="/Account/Login">
                      Đăng nhập
                    </a>
                  </nav>
                  <div className="nav-social">
                    <p>
                      <a href="//www.facebook.com/phansipangdiemhen">
                        <img alt="" src="/home/images/icon-fb.png" />
                      </a>
                      <a href="#">
                        <img alt="" src="/home/images/icon-tw.png" />
                      </a>
                      <a href="#">
                        <img alt="" src="/home/images/icon-youtube.png" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end footer */}
    </div>
  );
};

export default Quiz;
