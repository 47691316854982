import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import authUtils from "../../utils/authUtils";
import Loading from "../common/Loading";
import Sidebar from "../common/Sidebar";
import Header from "../Header";
import authApi from "../../api/authApi";

const AppLayout = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const router = useLocation();
  const { pathname } = router;

  const getUser = async () => {
    try {
      const res = await authApi.verifyToken();
      setUser(res.user);
    } catch {
      return false;
    }
  };
  useEffect(() => {
    const checkAuth = async () => {
      const user = await authUtils.isAuthenticated();
      if (!user) {
        navigate("/login");
      } else {
        // save user
        setUser(user);
        setLoading(false);
      }
    };
    if (pathname !== "/") {
      checkAuth();
    }
    if (pathname === "/") {
      getUser();
    }
  }, [navigate, pathname]);

  return loading ? (
    <Loading fullHeight />
  ) : (
    // <Box sx={{
    //   display: 'flex'
    // }}>
    //   <Sidebar />
    //   <Box sx={{
    //     flexGrow: 1,
    //     p: 1,
    //     width: 'max-content'
    //   }}>
    //     <Outlet />
    //   </Box>
    // </Box>
    <>
      <Header user={user} />
      <Outlet />
    </>
  );
};

export default AppLayout;
