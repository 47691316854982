import React, { useState, useEffect } from "react";

function CountdownTimer({onChange}) {
  const [timeLeft, setTimeLeft] = useState({ minutes: 30, seconds: 0 });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        const updatedSeconds = prevTime.seconds - 1;
        const updatedMinutes = prevTime.minutes - (updatedSeconds < 0 ? 1 : 0);
  
        // Kiểm tra nếu phút và giây đều bằng 0 thì dừng lại
        if (updatedMinutes === 0 && updatedSeconds === 0) {
          clearInterval(timer);
        }
  
        return {
          minutes: updatedMinutes,
          seconds: updatedSeconds < 0 ? 59 : updatedSeconds,
        };
      });
    }, 1000);
  
    return () => clearInterval(timer);
  }, []);
  

  useEffect(() => {
    if (timeLeft.minutes === 0 && timeLeft.seconds === 0) {
      console.log("timeLeft", timeLeft)
      onChange()
    }
  }, [timeLeft]);

  const formattedTime = `${timeLeft.minutes
    .toString()
    .padStart(2, "0")}:${timeLeft.seconds.toString().padStart(2, "0")}`;

  return <>Thời gian làm bài còn: {formattedTime}</>;
}

export default CountdownTimer;
