import { useEffect } from "react";

// Hook kiểm tra thời gian liên tục
const useTimeCheck = (startTime, endTime, onValidTime) => {
  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      if (now >= startTime && now <= endTime) {
        if (now >= startTime) {
          // Thời gian hợp lệ, gọi callback function
          onValidTime();
        }
      }
    };

    // Thực hiện kiểm tra thời gian ban đầu
    checkTime();

    // Thiết lập interval để kiểm tra thời gian liên tục (mỗi giây)
    const interval = setInterval(checkTime, 1000);

    // Xóa interval khi component bị unmount
    return () => {
      clearInterval(interval);
    };
  }, [startTime, endTime, onValidTime]);
};

export default useTimeCheck;
