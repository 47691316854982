import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import quizApi from "../api/quizApi";
export const STATUS = {
  CHUA_THI: 1,
  DANG_THI: 2,
  DA_THI: 3,
};
const Noti = () => {
  const navigate = useNavigate();
  const [urlParamsObj, setUrlParamsObj] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const keys = urlParams.keys();
    const paramsObj = {};

    for (const key of keys) {
      const value = urlParams.get(key);
      paramsObj[key] = value;
    }

    setUrlParamsObj(paramsObj);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [urlParamsObj]);

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{ height: "100vh" }}>
      <main>
        <div className="container">
          <div className="row g-3 mb-3">
            <div className="col-xxl-12 col-xl-12">
              <div className="card mb-3">
                <div className="card-header position-relative">
                  <div
                    className="bg-holder d-none d-md-block bg-card"
                    style={{
                      backgroundImage:
                        "url(../../../assets/img/illustrations/corner-6.png)",
                    }}
                  />
                  {/*/.bg-holder*/}
                </div>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n                      legend {\n                      border: 0;\n                      width: 100%;\n                      margin: 0;\n                      padding: 0;\n                      -webkit-margin-collapse: separate;\n                      font-size: 1em;\n                      font-weight: 400;\n                      line-height: 1.5em;\n                      color: black;\n                  }\n                  legend strong {\n                      display: block;\n                      float: left;\n                      color: white;\n                      text-align: center;\n                      background: #212121;\n                      border-radius: 50%;\n                      height: 2em;\n                      width: 2em;\n                      padding: 0;\n                      margin: -0.25em 0.5em 0 0;\n                      line-height: 2em;\n                      font-family: inherit !important;\n                      font-weight: bold;\n                  }\n                  .khanhadf  label {\n                      border: 1px solid #9e9e9e;\n                      border-radius: 8px;\n                      background: #fafafa;\n                      padding: 16px 8px 16px 48px;\n                      font-weight: normal;\n                      float: none;\n                      max-width: 436px;\n                      box-sizing: border-box;\n                      width: auto;\n                  }\n                  ",
                }}
              />
              <article className="card mb-3 overflow-hidden">
                <div className="card-body p-0">
                  <div className="row g-0">
                    <div className="col-md-12 p-x1">
                      <div className="row g-0 h-100">
                        <div className="col-lg-12 d-flex flex-column pe-x1 khanhadf">
                          <div>
                            <h3>{urlParamsObj?.name}</h3>
                          </div>
                          <div>{urlParamsObj?.path}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <div className="card">
                <div className="card-body">
                  <div className="row g-3 flex-center justify-content-md-between">
                    <div className="col-auto"></div>
                    <div className="mb-3">
                      <button
                        className="btn btn-primary d-block w-100 mt-3"
                        onClick={() => navigate("/")}>
                        Trang Chủ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* footer */}
      <div className="row g-0" style={{ backgroundColor: "#006abc" }}>
        <div className="col-lg-12 mb-12">
          <div
            className="card h-lg-100 overflow-hidden"
            style={{ background: "#006abc" }}>
            <div className="container">
              <div className="row" style={{ background: "#006abc" }}>
                <div
                  className="col-sm-12 col-md-7 d-flex"
                  style={{
                    display: "flex",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                  }}>
                  <div className="footer-logo">
                    <img
                      src="https://tuyengiao-tracnghiem.laocai.gov.vn/images/logo-dang.png"
                      alt="HỆ THỐNG THI TRẮC NGHIỆM TRỰC TUYẾN "
                      className="img-responsive"
                    />
                  </div>
                  <div
                    className="footer-content"
                    style={{ padding: "0px 10px" }}>
                    <h3>HỆ THỐNG THI TRẮC NGHIỆM TRỰC TUYẾN</h3>
                    <p>
                      <strong>- Cơ quan phụ trách:</strong> Ban Tuyên giáo Tỉnh
                      ủy
                      <br />
                      <strong>- Địa chỉ:</strong> Trụ sở khối 1, phường Nam
                      Cường, Thành phố , Tỉnh <br />
                      <strong>- Điện thoại:</strong> 0987733a2323
                      <br />
                      <strong>- Email:</strong> dasdasdasdasd@laocai.gov.vn
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 pull-right">
                  <nav className="nav nav-footer">
                    <a className="nav-link" href="/">
                      Trang chủ
                    </a>
                    <a className="nav-link" href="/article/index?code=hd">
                      Hướng dẫn
                    </a>
                    <a className="nav-link" href="/article/index?code=lh">
                      Liên hệ
                    </a>
                    <a className="nav-link" href="/Account/Login">
                      Đăng nhập
                    </a>
                  </nav>
                  <div className="nav-social">
                    <p>
                      <a href="//www.facebook.com/phansipangdiemhen">
                        <img alt="" src="/home/images/icon-fb.png" />
                      </a>
                      <a href="#">
                        <img alt="" src="/home/images/icon-tw.png" />
                      </a>
                      <a href="#">
                        <img alt="" src="/home/images/icon-youtube.png" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end footer */}
    </div>
  );
};

export default Noti;
