import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authApi from "../api/authApi";
import quizApi from "../api/quizApi";
import ButtonLoading from "../components/ButtonLoading";
import { unit_type } from "./const";
const Signup = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [usernameErrText, setUsernameErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");
  const [confirmPasswordErrText, setConfirmPasswordErrText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUsernameErrText("");
    setPasswordErrText("");
    setConfirmPasswordErrText("");

    const data = new FormData(e.target);
    const username = data.get("username").trim();
    const password = data.get("password");
    const fullName = data.get("fullName").trim();
    const dateOfBirth = data.get("dateOfBirth");
    const email = data.get("email");
    const unit = data.get("unit");
    const confirmPassword = data.get("confirmPassword");

    let err = false;

    if (username === "") {
      err = true;
      setUsernameErrText("Please fill this field");
    }
    if (password === "") {
      err = true;
      setPasswordErrText("Please fill this field");
    }
    if (confirmPassword === "") {
      err = true;
      setConfirmPasswordErrText("Please fill this field");
    }

    if (err) return;
    if (loading) return;
    setLoading(true);

    try {
      await authApi
        .signup({
          username,
          password,
          confirmPassword,
          fullName,
          email,
          dateOfBirth,
          unit,
        })
        .then((res) => {
          const { user, token } = res;
          const { id } = user;
          localStorage.setItem("token", token);
          localStorage.setItem("userQuiz", JSON.stringify(user));
          quizApi.createQiz({ userId: id }).then((res) => {
            setLoading(false);
            navigate("/");
          });
        });
    } catch (err) {
      const errors = err.data.errors;

      errors.forEach((e) => {
        if (e.param === "username") {
          setUsernameErrText(e.msg);
          alert(e.msg);
        }
        if (e.param === "password") {
          alert(e.msg);
          setPasswordErrText(e.msg);
        }
        if (e.param === "confirmPassword") {
          alert(e.msg);
          setConfirmPasswordErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center g-0">
          <div className="col-lg-8 col-xxl-5 py-3 position-relative">
            <img
              className="bg-auth-circle-shape"
              src="../../../assets/img/icons/spot-illustrations/bg-shape.png"
              alt=""
              width={250}
            />
            <img
              className="bg-auth-circle-shape-2"
              src="../../../assets/img/icons/spot-illustrations/shape-1.png"
              alt=""
              width={150}
            />
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row g-0 h-100">
                  <div className="col-md-5 text-center bg-card-gradient">
                    <div
                      className="position-relative p-4 pt-md-5 pb-md-7"
                      data-bs-theme="light">
                      <div
                        className="bg-holder bg-auth-card-shape"
                        style={{
                          backgroundImage:
                            "url(../../../assets/img/icons/spot-illustrations/half-circle.png)",
                        }}
                      />
                      {/*/.bg-holder*/}
                      <div className="z-1 position-relative">
                        <img
                          src="https://tuyengiao-tracnghiem.laocai.gov.vn/images/logo-dang.png"
                          width="167px"
                        />
                      </div>
                    </div>
                    <div
                      className="mt-3 mb-4 mt-md-4 mb-md-5"
                      data-bs-theme="light">
                      <p className="text-white">
                        Bạn Có Tài Khoản ?<br />
                        <a
                          className="text-decoration-underline link-light"
                          href="register.html"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-md-7 d-flex flex-center">
                    <div className="p-4 p-md-5 flex-grow-1">
                      <h3>Đăng Ký</h3>
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="card-name">
                            Họ Và Tên
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="fullName"
                            autoComplete="on"
                            id="card-name"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="card-name">
                            Username
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="username"
                            autoComplete="on"
                            id="card-name"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="card-email">
                            Email{" "}
                          </label>
                          <input
                            name="email"
                            className="form-control"
                            type="email"
                            autoComplete="on"
                            id="card-email"
                          />
                        </div>
                        <div className="mb-3 ">
                          <label className="form-label" htmlFor="card-password">
                            Ngày Tháng Sinh
                          </label>
                          <input
                            name="dateOfBirth"
                            className="form-control"
                            type="date"
                            autoComplete="on"
                            id="card-password"
                          />
                        </div>
                        <div className="mb-3 ">
                          <label
                            className="form-label"
                            htmlFor="card-confirm-password">
                            Đơn Vị
                          </label>
                          <select
                            id="fruits"
                            name="unit"
                            className="form-control">
                            {unit_type.map((v, i) => (
                              <option value={v.id}>{v.label}</option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3 ">
                          <label className="form-label" htmlFor="card-password">
                            {" "}
                            Mật Khẩu
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            name="password"
                            autoComplete="on"
                            id="card-password"
                          />
                        </div>
                        <div className="mb-3 ">
                          <label
                            className="form-label"
                            htmlFor="card-confirm-password">
                            Nhập Lại Mật Khẩu
                          </label>
                          <input
                            className="form-control"
                            name="confirmPassword"
                            type="password"
                            autoComplete="on"
                            id="card-confirm-password"
                          />
                        </div>
                        <div className="mb-3">
                          <ButtonLoading
                            title="Đăng ký"
                            loading={loading}
                            background="red"
                          />
                          {/* <button
                            className="btn btn-primary d-block w-100 mt-3"
                            style={{
                              background: "red",
                              border: "1px solid red",
                            }}>
                            {
                              loading ?
                              <>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
                              </>
                              : "Đăng ký"
                            }
                          </button> */}
                        </div>
                      </form>
                      <div className="mb-3">
                        <a
                          className="btn btn-primary d-block w-100 mt-3"
                          href="/login">
                          Đăng Nhập
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Signup;
