import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import authApi from "../api/authApi";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [usernameErrText, setUsernameErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUsernameErrText("");
    setPasswordErrText("");

    const data = new FormData(e.target);
    const username = data.get("email").trim();
    const password = data.get("password").trim();
    console.log("hihihi", { username, password });
    let err = false;

    if (username === "") {
      err = true;
      setUsernameErrText("Please fill this field");
    }
    if (password === "") {
      err = true;
      setPasswordErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    try {
      const res = await authApi.login({ username, password });
      setLoading(false);
      localStorage.setItem("token", res.token);
      localStorage.setItem("userQuiz", JSON.stringify(res.user))
      navigate("/");
    } catch (err) {
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "username") {
          setUsernameErrText(e.msg);
        }
        if (e.param === "password") {
          setPasswordErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center g-0">
          <div className="col-lg-8 col-xxl-5 py-3 position-relative">
            <img
              className="bg-auth-circle-shape"
              src="../../../assets/img/icons/spot-illustrations/bg-shape.png"
              alt=""
              width={250}
            />
            <img
              className="bg-auth-circle-shape-2"
              src="../../../assets/img/icons/spot-illustrations/shape-1.png"
              alt=""
              width={150}
            />
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row g-0 h-100">
                  <div className="col-md-5 text-center bg-card-gradient">
                    <div
                      className="position-relative p-4 pt-md-5 pb-md-7"
                      data-bs-theme="light">
                      <div
                        className="bg-holder bg-auth-card-shape"
                        style={{
                          backgroundImage:
                            "url(../../../assets/img/icons/spot-illustrations/half-circle.png)",
                        }}
                      />
                      {/*/.bg-holder*/}
                      <div className="z-1 position-relative">
                        <img
                          src="https://tuyengiao-tracnghiem.laocai.gov.vn/images/logo-dang.png"
                          width="167px"
                        />
                      </div>
                    </div>
                    <div
                      className="mt-3 mb-4 mt-md-4 mb-md-5"
                      data-bs-theme="light">
                      <p className="text-white">
                        Bạn Chưa Có Tài Khoản ?<br />
                        <a
                          className="text-decoration-underline link-light"
                          href="register.html"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-md-7 d-flex flex-center">
                    <div className="p-4 p-md-5 flex-grow-1">
                      <div className="row flex-between-center">
                        <div className="col-auto">
                          <h3>ĐĂNG NHẬP</h3>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="card-email">
                            Tài Khoản
                          </label>
                          <input
                            className="form-control"
                            name="email"
                            id="card-email"
                            // type="email"
                          />
                          <span>{usernameErrText}</span>
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label
                              className="form-label"
                              htmlFor="card-password">
                              Mật Khẩu
                            </label>
                          </div>
                          <input
                            className="form-control"
                            name="password"
                            id="card-password"
                            type="password"
                          />
                          <span>{passwordErrText}</span>
                        </div>
                        <div className="row flex-between-center">
                          <div className="col-auto">
                            <div className="form-check mb-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="card-checkbox"
                                defaultChecked="checked"
                              />
                              <label
                                className="form-check-label mb-0"
                                htmlFor="card-checkbox">
                                Ghi Nhớ
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          {/* <a className="btn btn-primary d-block w-100 mt-3" href="/index2.html">Đăng Nhập</a> */}
                          <button
                            type="submit"
                            className="btn btn-primary d-block w-100 mt-3">
                            Đăng Nhập
                          </button>
                        </div>
                        <div className="mb-3">
                          <a
                            className="btn btn-primary d-block w-100 mt-3"
                            style={{
                              background: "red",
                              border: "1px solid red",
                            }}
                            href="/signup">
                            Đăng Ký
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
