import axiosClient from "./axiosClient";

const quizApi = {
  getAllQuiz: (userId) => axiosClient.get(`quiz/getQuiz/${userId}`),
  getById: (id) => axiosClient.get(`quiz/${id}`),
  getQuizDate: () => axiosClient.get(`quiz/getQuizDate`),
  getTopUsersByScore: () => axiosClient.get(`quiz/getTopUsersByScore`),
  createQiz: (body) => axiosClient.post(`quiz`, body),
  submitQuiz: (body) => axiosClient.post(`quiz/submitQuiz`, body),
};

export default quizApi;
