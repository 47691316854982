import useTimeCheck from "../components/common/useCheckTime";
import { STATUS, renderTime } from "./Home";
import moment from "moment";
const CardItem = ({ data, handleDetail, onCallBack }) => {
  useTimeCheck(data?.startTime, data?.endTime, onCallBack);
  const renderStatus = (startTime, endTime) => {
    const now = moment();
    const endTimeObj = moment(endTime);
    const startTimeObj = moment(startTime);
    if (now.isBefore(startTimeObj)) {
      return STATUS.CHUA_THI;
    } else if (now.isBetween(startTimeObj, endTimeObj)) {
      return STATUS.DANG_THI;
    } else {
      return STATUS.DA_THI;
    }
  };
  const renderTxtStatus = (statusId) => {
    let status = "";
    switch (statusId) {
      case STATUS.CHUA_THI:
        status = "Chưa tới ngày thi";
        break;
      case STATUS.DANG_THI:
        status = "Đang thi";
        break;
      case STATUS.DA_THI:
        status = "Đã kết thúc";
        break;
      default:
        break;
    }
    return status;
  };
  return (
    <div
      className="col-md-6 col-xxl-3 border-top"
      onClick={() => handleDetail(data)}>
      <div className="card h-md-100 ecommerce-card-min-width khanhdz">
        <div className="card-header pb-0">
          <h3 className="mb-0 mt-2 d-flex align-items-center timeline-name">
            {data?.name}
          </h3>
        </div>
        <div className="card-body d-flex flex-column justify-content-end">
          <div className="row">
            <div className="col">
              <div className="timeline-desc">
                <p className="time">
                  {/* 09:00 20/03 - 14:00 26/03 */}
                  {renderTime(data?.startTime, data?.endTime)}
                </p>
                <p className="time">Đề thi chung các đơn vị</p>
                <p className="status">
                  {data?.isCompleted
                    ? "Đã thi"
                    : renderTxtStatus(
                        renderStatus(data?.startTime, data?.endTime)
                      )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
