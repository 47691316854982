import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { setBoards } from "../redux/features/boardSlice";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import boardApi from "../api/boardApi";
import { useState } from "react";
import { useEffect } from "react";
import quizApi from "../api/quizApi";
import moment from "moment";
import authUtils from "../utils/authUtils";
import { setUser } from "../redux/features/userSlice";
import CountdownTimer from "../components/CountDownTime";
import CardItem from "./CardItem";
import { unit_type } from "./const";
import notiApi from "../api/notiApi";
export const STATUS = {
  CHUA_THI: 1,
  DANG_THI: 2,
  DA_THI: 3,
};
export const renderTime = (startTime, endTime) => {
  const startTimeUtc = moment(startTime);
  const endTimeUtc = moment(endTime);
  const startTimeFormatted = startTimeUtc.format("HH:mm DD/MM");
  const endTimeFormatted = endTimeUtc.format("HH:mm DD/MM");
  return `${startTimeFormatted} - ${endTimeFormatted}`;
};
const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const [listQuiz, setListQuiz] = useState([]);
  const [rank, setRank] = useState([]);
  const [listNoti, setListNoti] = useState([]);
  const userStore = useSelector((state) => state.user);
  const [endDate, setEndDate] = useState("");
  const [rangeDate, setRangeDate] = useState("");
  const checkAuth = async () => {
    const user = await authUtils.isAuthenticated();
    if (!user) {
      navigate("/login");
    } else {
      // save user
      localStorage.setItem("userQuiz", JSON.stringify(user));
      return true;
    }
  };

  const handleDetail = (data) => {
    const now = moment();
    const endTimeObj = moment(data?.endTime);
    const startTimeObj = moment(data?.startTime);
    if (
      checkAuth() &&
      now.isBetween(startTimeObj, endTimeObj) &&
      !data?.isCompleted
    ) {
      navigate(`/quiz/${data?._id}`);
    }
  };

  const renderStatus = (startTime, endTime) => {
    const now = moment();
    const endTimeObj = moment(endTime);
    const startTimeObj = moment(startTime);
    if (now.isBefore(startTimeObj)) {
      return STATUS.CHUA_THI;
    } else if (now.isBetween(startTimeObj, endTimeObj)) {
      return STATUS.DANG_THI;
    } else {
      return STATUS.DA_THI;
    }
  };

  const renderTxtStatus = (statusId) => {
    let status = "";
    switch (statusId) {
      case STATUS.CHUA_THI:
        status = "Chưa tới ngày thi";
        break;
      case STATUS.DANG_THI:
        status = "Đang thi";
        break;
      case STATUS.DA_THI:
        status = "Đã kết thúc";
        break;
      default:
        break;
    }
    return status;
  };

  const getAllQuiz = async () => {
    try {
      const user = JSON.parse(await localStorage.getItem("userQuiz"));
      const { id } = user;
      const res = await quizApi.getAllQuiz(id);
      setListQuiz(res);
    } catch (error) {
      console.log(error);
    }
  };
  const getTopUsersByScore = async () => {
    try {
      const res = await quizApi.getTopUsersByScore();
      setRank(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getQuizDate = async () => {
    try {
      const res = await quizApi.getQuizDate();
      setEndDate(res.endDate);
      setRangeDate(res);
    } catch (error) {
      console.log(error);
    }
  };
  const getNotiAll = async () => {
    try {
      const res = await notiApi.getAllNoti();
      setListNoti(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuizDate();
    getAllQuiz();
    getTopUsersByScore();
    getNotiAll();
  }, []);

  const renderUnit = (v) => {
    const data = unit_type.find((item) => item.id == v?.user?.unit);
    return data?.label;
  };

  const check = async () => {
    try {
      const data = await authUtils.isAuthenticated();
      const quizStart = listQuiz.find((v) => v.week == 1).startTime;
      const startDate = moment(quizStart);
      const rangeStart = moment(rangeDate.startDate);
      if (!startDate.isSame(rangeStart, "second")) {
        setListQuiz([]);
        quizApi.createQiz({ userId: data?._id }).then((res) => {
          getAllQuiz();
          getTopUsersByScore();
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (listQuiz && rangeDate) {
      check();
    }
  }, [listQuiz, rangeDate]);

  return (
    <div>
      <main className="main" id="top" style={{ padding: "20px 0px" }}>
        <div className="container" data-layout="container">
          <div className="content">
            <div className="row g-3 mb-3">
              <div className="col-md-12">
                <div className="card h-md-100 ecommerce-card-min-width">
                  <img src="./assets/img/imgNew/bia1matngoai.png" />
                </div>
              </div>
              {/* <div className="col-md-12 col-xxl-6">
                <div className="card h-md-100 ecommerce-card-min-width">
                  <img
                    src="./assets/img/imgNew/t1donee.png"
                    className="anhnewmoi"
                  />
                </div>
              </div> */}
              <div
                className="col-md-12 col-xxl-12"
                style={{ padding: "10px 16px" }}>
                <div
                  className="row g-3 mb-3"
                  style={{ backgroundColor: "#006abc" }}>
                  <div className="col-md-12 col-xxl-6 mx-auto">
                    <div
                      className="text-center rounded-end"
                      style={{
                        minHeight: "370px",
                        backgroundColor: "#006abc",
                      }}>
                      <h5 className="text-white text-uppercase pt-5 pb-4 time-left">
                        Thời gian còn lại của cuộc thi
                      </h5>
                      <CountdownTimer endDateDefault={endDate} />
                      <br />
                      <div className="d-grid gap-2 col-8 mx-auto">
                        {/* <button className="btn btn-outline-light text-uppercase fw-bold">
                          Xem thể lệ cuộc thi
                        </button> */}
                        {/* <a
                          className="btn btn-warning mt-2 text-white text-uppercase fw-bold"
                          title>
                          Vào thi ngay
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="arrow-right"
                            className="svg-inline--fa fa-arrow-right"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            style={{ fontSize: "14px", color: "#fff" }}>
                            <path
                              fill="currentColor"
                              d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
                            />
                          </svg>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '\n                .khanhdz {\n                  margin-top: 30px;\n                }\n                .khanhdz::after {\n                  content: "";\n                  position: absolute;\n                  top: -41px;\n                  left: calc(50% - 5px);\n                  bottom: 0;\n                  width: 20px;\n                  height: 20px;\n                  border-radius: 50%;\n                  background: #dd3b37;\n                  border: 2px solid #ebebeb;\n                }\n                .khanhdz::before {\n                  content: "";\n                  position: absolute;\n                  left: calc(50% - 3px);\n                  width: 0;\n                  height: 0;\n                  border-style: solid;\n                  top: -6px;\n                  border-width: 0px 8px 6px 8px;\n                  border-color: transparent transparent #dd3b37 transparent;\n                }\n              ',
                }}
              />
              <div className="row g-3 mb-3" style={{ paddingRight: 0 }}>
                <div className="col-md-12 col-xxl-12">
                  <div className="card h-md-100 ecommerce-card-min-width">
                    <div className="timeline-title">
                      <h3>CÁC CHẶNG CUỘC THI</h3>
                    </div>
                  </div>
                </div>
                {listQuiz &&
                  listQuiz.map((v) => (
                    <CardItem
                      data={v}
                      handleDetail={handleDetail}
                      onCallBack={getAllQuiz}
                    />
                  ))}
                {/* <div className="col-md-6 col-xxl-3 border-top">
                  <div className="card h-md-100 ecommerce-card-min-width khanhdz">
                    <div className="card-header pb-0">
                      <h3 className="mb-0 mt-2 d-flex align-items-center timeline-name">
                        75 Năm ngày truyền thống Lực lượng Vũ trang tỉnh ( Tuần
                        1 )
                      </h3>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-end">
                      <div className="row">
                        <div className="col">
                          <div className="timeline-desc">
                            <p className="time">09:00 20/03 - 14:00 26/03</p>
                            <p className="time">Đề thi chung các đơn vị</p>
                            <p className="status">Đã kết thúc</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-3 border-top">
                  <div className="card h-md-100 ecommerce-card-min-width khanhdz">
                    <div className="card-header pb-0">
                      <h3 className="mb-0 mt-2 d-flex align-items-center timeline-name">
                        75 Năm ngày truyền thống Lực lượng Vũ trang tỉnh ( Tuần
                        2 )
                      </h3>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-end">
                      <div className="row">
                        <div className="col">
                          <div className="timeline-desc">
                            <p className="time">09:00 20/03 - 14:00 26/03</p>
                            <p className="time">Đề thi chung các đơn vị</p>
                            <p className="status">Đã kết thúc</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-3 border-top">
                  <div className="card h-md-100 ecommerce-card-min-width khanhdz">
                    <div className="card-header pb-0">
                      <h3 className="mb-0 mt-2 d-flex align-items-center timeline-name">
                        75 Năm ngày truyền thống Lực lượng Vũ trang tỉnh ( Tuần
                        3 )
                      </h3>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-end">
                      <div className="row">
                        <div className="col">
                          <div className="timeline-desc">
                            <p className="time">09:00 20/03 - 14:00 26/03</p>
                            <p className="time">Đề thi chung các đơn vị</p>
                            <p className="status">Đã kết thúc</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-3 border-top">
                  <div className="card h-md-100 ecommerce-card-min-width khanhdz">
                    <div className="card-header pb-0">
                      <h3 className="mb-0 mt-2 d-flex align-items-center timeline-name">
                        75 Năm ngày truyền thống Lực lượng Vũ trang tỉnh ( Tuần
                        4 )
                      </h3>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-end">
                      <div className="row">
                        <div className="col">
                          <div className="timeline-desc">
                            <p className="time">09:00 20/03 - 14:00 26/03</p>
                            <p className="time">Đề thi chung các đơn vị</p>
                            <p className="status">Đã kết thúc</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className="row g-0 khanhcc"
              style={{ paddingRight: "20px", paddingTop: "10px" }}>
              <div className="col-lg-6 pe-lg-2 mb-3">
                <div className="card h-lg-100 overflow-hidden">
                  <div className="kanban-column" style={{ width: "100%" }}>
                    <div className="kanban-column-header">
                      <h5 className="fs-0 mb-0">
                        THÔNG BÁO CỦA BAN TỔ CHỨC
                        <span className="text-500">({listNoti?.length})</span>
                      </h5>
                      <div className="dropdown font-sans-serif btn-reveal-trigger">
                        <button
                          className="btn btn-sm btn-reveal py-0 px-2"
                          type="button"
                          id="kanbanColumn0"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <svg
                            className="svg-inline--fa fa-ellipsis-h fa-w-16"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="ellipsis-h"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg>
                            <path
                              fill="currentColor"
                              d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                            />
                          </svg>
                          {/* <span class="fas fa-ellipsis-h"></span> Font Awesome fontawesome.com */}
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end py-0"
                          aria-labelledby="kanbanColumn0">
                          <a className="dropdown-item" href="#!">
                            Add Card
                          </a>
                          <a className="dropdown-item" href="#!">
                            Edit
                          </a>
                          <a className="dropdown-item" href="#!">
                            Copy link
                          </a>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item text-danger" href="#!">
                            Remove
                          </a>
                        </div>
                      </div>
                    </div>
                    {/*kanban item*/}
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n                    .notification-list .list-item {\n                      display: -webkit-box;\n                     margin-bottom: 10px;\n   background: white;\n                    display: -ms-flexbox;\n                      display: flex;\n                      padding: 0;\n                      align-items: center;\n                      -webkit-align-items: center;\n                      position: relative;\n                    }\n                                        .notification-list .list-item .date {\n                      width: 20%;\n                      padding: 5px 15px;\n                      text-align: center;\n                      margin-bottom: 0;\n                      font-weight: 500;\n                    }\n                    .notification-list .list-item .title {\n                      margin: 0;\n                      font-weight: 400;\n                      padding: 10px 0px;\n                      width: 100%;\n                      line-height: 23px;\n                                         font-size: var(--font-size-base);\n                      font-weight: 500;\n                      font-size: 14px;\n                    }\n                    .notification-list .list-item .title a {\n                      text-decoration: none;\n                      color: #222;\n                    }\n                    .notification-list .list-item .title a:hover {\n                      color: #23527c;\n                    }\n                  ",
                      }}
                    />
                    <div className="notification-list scrollbar kanban-items-container">
                      {listNoti &&
                        listNoti.map((v, i) => (
                          <div
                            className="list-item"
                            style={{
                              flexDirection: "column",
                              alignItems: "start",
                              padding: "15px",
                              cursor: "pointer",
                            }}>
                            <p
                              className="title"
                              onClick={() => {
                                const params = new URLSearchParams();
                                for (const key in v) {
                                  params.append(key, v[key]);
                                }

                                const urlParams = params.toString();
                                navigate(`/noti?${urlParams}`);
                              }}>
                              {v.name}
                            </p>
                            <p
                              style={{
                                margin: 0,
                                color: "#838383",
                                fontSize: "11px",
                              }}>
                              Ngày đăng{" "}
                              {moment(v.created_at).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        ))}
                    </div>
                    {/* <div className="kanban-column-footer">
                      <button
                        className="btn btn-link btn-sm d-block w-100 btn-add-card text-decoration-none text-600"
                        type="button">
                        Xem Thêm
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ps-lg-2 mb-3">
                <div className="card h-lg-100">
                  <div className="card-header">
                    <div className="row flex-between-center">
                      <div className="col-auto">
                        <h6 className="mb-0">
                          THỐNG KÊ ĐỢT THI ĐANG DIỄN RA (TOP 10)
                        </h6>
                      </div>
                      {/* <div className="col-auto d-flex">
                        <select className="form-select form-select-sm select-month me-2">
                          <option value={0}>January</option>
                          <option value={1}>February</option>
                          <option value={2}>March</option>
                          <option value={3}>April</option>
                          <option value={4}>May</option>
                          <option value={5}>Jun</option>
                          <option value={6}>July</option>
                          <option value={7}>August</option>
                          <option value={8}>September</option>
                          <option value={9}>October</option>
                          <option value={10}>November</option>
                          <option value={11}>December</option>
                        </select>
                        <div className="dropdown font-sans-serif btn-reveal-trigger">
                          <button
                            className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal"
                            type="button"
                            id="dropdown-total-sales"
                            data-bs-toggle="dropdown"
                            data-boundary="viewport"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <span className="fas fa-ellipsis-h fs--2" />
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-end border py-2"
                            aria-labelledby="dropdown-total-sales">
                            <a className="dropdown-item" href="#!">
                              View
                            </a>
                            <a className="dropdown-item" href="#!">
                              Export
                            </a>
                            <div className="dropdown-divider" />
                            <a className="dropdown-item text-danger" href="#!">
                              Remove
                            </a>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div class="card-body h-100 pe-0">
                    <ol class="list-group list-group-numbered">
                      {rank &&
                        rank.map((v, i) => (
                          <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div class="ms-2 me-auto">
                              <div class="fw-bold">{v?.user?.fullName}</div>
                              Đơn vị - {renderUnit(v)}
                            </div>
                            <span class="badge bg-primary rounded-pill">
                              {v?.score}
                            </span>
                          </li>
                        ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
